import {
  CHAT_ADD,
  GET_CHAT_HISTORY,
  CHAT_LOADING,
  CHAT_REMOVED
} from '../actions/types';

const initialState = {
  chats: [],
  count: 0,
  last_id: 0,
  least_id: 0,
  loading:false
};

export default (state = initialState, action) => {
  switch(action.type) {

      case CHAT_LOADING:
       return {
          ...state,
          loading:action.payload
        }
      case GET_CHAT_HISTORY:
        if(Array.isArray(action.payload) && action.payload.length>0){
          let sorted_chat = [...action.payload, ...state.chats];
          sorted_chat = Array.from(new Set(sorted_chat.map(a => a._id)))
          .map(id => {
            return sorted_chat.find(a => a._id === id)
          }).sort((a, b) => {
            return a._id.localeCompare(b._id);
          });
          const last_id = sorted_chat[sorted_chat.length-1]._id;
          const least_id = sorted_chat[0]._id;

          return {
            ...state,
            chats: [...sorted_chat],
            last_id,
            least_id,
            count: sorted_chat.length,
            loading:false
          }
        }
        else return {
          ...state,
          loading:false
        }
      case CHAT_ADD:
        {
          let sorted_chat = [action.payload, ...state.chats];
          sorted_chat = Array.from(new Set(sorted_chat.map(a => a._id)))
          .map(id => {
            return sorted_chat.find(a => a._id === id)
          }).sort((a, b) => {
            return a._id.localeCompare(b._id);
          });

          const last_id = sorted_chat[sorted_chat.length-1]._id;
          const least_id = sorted_chat[0]._id;

          return {
            ...state,
            chats: [...sorted_chat],
            last_id,
            least_id,
            count: sorted_chat.length,
            loading:false
          };
        }
      case CHAT_REMOVED:
        {
          let chat_list = [...state.chats].filter(item=>item._id!=action.payload);
          const last_id = chat_list[chat_list.length-1]._id;
          const least_id = chat_list[0]._id;
          return {
            ...state,
            chats: [...chat_list],
            last_id,
            least_id,
            count: chat_list.length,
            loading:false
          };
        }
      default:
          return state;
  }
}
