export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const USERS_GET = 'USERS_GET';
export const USERS_REGISTERED = 'USERS_REGISTERED';
export const USERS_CLEAR = 'USERS_CLEAR';
export const USERS_SET_NEXT = 'USERS_SET_NEXT';
export const USERS_SET_PREVIOUS = 'USERS_SET_PREVIOUS';
export const USERS_SET_SEARCH = 'USERS_SET_SEARCH';
export const USERS_NEXT = 'USERS_NEXT';
export const USERS_PREVIOUS = 'USERS_PREVIOUS';
export const USERS_FILTER = 'USERS_FILTER';
export const USERS_DELETED = 'USERS_DELETED';
export const USERS_ADD = 'USERS_ADD';
export const USER_EDITED = 'USER_EDITED';

export const POSTS_GET = 'POSTS_GET';
export const POSTS_NEW = 'POSTS_NEW';
export const POSTS_CLEAR = 'POSTS_CLEAR';
export const POSTS_SET_NEXT = 'POSTS_SET_NEXT';
export const POSTS_SET_PREVIOUS = 'POSTS_SET_PREVIOUS';
export const POSTS_SET_SEARCH = 'POSTS_SET_SEARCH';
export const POSTS_NEXT = 'POSTS_NEXT';
export const POSTS_PREVIOUS = 'POSTS_PREVIOUS';
export const POSTS_FILTER = 'POSTS_FILTER';
export const POSTS_DELETED = 'POSTS_DELETED';
export const POSTS_ADD = 'POSTS_ADD';
export const POSTS_EDITED = 'POSTS_EDITED';
export const FILES_APPROVED = 'FILES_APPROVED';
export const FILES_REMOVED = 'FILES_REMOVED';
export const POSTS_APPROVED = 'POSTS_APPROVED';
export const POSTS_UPDATE = 'POSTS_UPDATE';
export const COMMENTS_REPLIED = 'COMMENTS_REPLIED';

export const COMMENTS_GET = 'COMMENTS_GET';
export const COMMENTS_ADD = 'COMMENTS_ADD';
export const COMMENTS_CLEAR = 'COMMENTS_CLEAR';
export const COMMENTS_SET_NEXT = 'COMMENTS_SET_NEXT';
export const COMMENTS_SET_PREVIOUS = 'COMMENTS_SET_PREVIOUS';
export const COMMENTS_SET_SEARCH = 'COMMENTS_SET_SEARCH';
export const COMMENTS_NEXT = 'COMMENTS_NEXT';
export const COMMENTS_PREVIOUS = 'COMMENTS_PREVIOUS';
export const COMMENTS_FILTER = 'COMMENTS_FILTER';
export const COMMENTS_DELETED = 'COMMENTS_DELETED';
export const COMMENTS_EDITED = 'COMMENTS_EDITED';
export const COMMENTS_APPROVED = 'COMMENTS_APPROVED';

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const UPLOAD_GALLERY = 'UPLOAD_GALLERY';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const GET_FILES = 'GET_FILES';
export const SET_FILES = 'SET_FILES';
export const GET_COMMENTS = 'GET_COMMENTS';

export const FILES_GALLERY_REMOVE = 'FILES_GALLERY_REMOVE';
export const FILES_MEDIA_REMOVE = 'FILES_MEDIA_REMOVE';

export const CHAT_ADD = 'CHAT_ADD';
export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
export const SEND_CHAT = 'SEND_CHAT';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_REMOVE = 'CHAT_REMOVE';
export const CHAT_REMOVED = 'CHAT_REMOVED';


export const SURVEY_GET = 'SURVEY_GET';
export const SURVEY_CLEAR = 'SURVEY_CLEAR';
export const SURVEY_SET_NEXT = 'SURVEY_SET_NEXT';
export const SURVEY_SET_PREVIOUS = 'SURVEY_SET_PREVIOUS';
export const SURVEY_SET_SEARCH = 'SURVEY_SET_SEARCH';
export const SURVEY_NEXT = 'SURVEY_NEXT';
export const SURVEY_PREVIOUS = 'SURVEY_PREVIOUS';
export const SURVEY_FILTER = 'SURVEY_FILTER';
export const SURVEY_DELETED = 'SURVEY_DELETED';
export const SURVEY_ADD = 'SURVEY_ADD';
export const SURVEY_EDITED = 'SURVEY_EDITED';
export const SURVEY_REGISTERED = 'SURVEY_REGISTERED';