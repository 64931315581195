export const CATEGORY_EVENTS = 'CATEGORY_EVENTS';
export const CATEGORY_LIBRARY = 'CATEGORY_LIBRARY';
export const CATEGORY_COMPETITORS = 'CATEGORY_COMPETITORS';
export const CATEGORY_PILLS = 'CATEGORY_PILLS';
export const CATEGORY_BLOG = 'CATEGORY_BLOG';
export const CATEGORY_PODCAST = 'CATEGORY_PODCAST';
export const TRIMBOW = 'TRIMBOW';
export const FOSTER = 'FOSTER';

export const CategoryDictionary = {
  CATEGORY_EVENTS: 'Eventi e Congressi',
  CATEGORY_COMPETITORS: 'Competitors',
  CATEGORY_PILLS: 'Breaking News',
  CATEGORY_BLOG: 'Blog',
  CATEGORY_MSL: 'MSL / Medica',
  CATEGORY_PODCAST: 'Podcast',
  TRIMBOW: 'TRIMBOW',
  FOSTER: 'FOSTER'
};

export default [
  {
    key: CATEGORY_EVENTS,
    value: 'Eventi e Congressi'
  },
  {
    key: CATEGORY_COMPETITORS,
    value: 'Competitors'
  },
  {
    key: CATEGORY_PILLS,
    value: 'Breaking News'
  },
  {
    key: CATEGORY_BLOG,
    value: 'Blog'
  },
  {
    key: CATEGORY_PODCAST,
    value: 'Podcast',
  },
  {
    key: TRIMBOW,
    value: 'TRIMBOW'
  },
  {
    key: FOSTER,
    value: 'FOSTER',
  }
];
