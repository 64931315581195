import {
    POSTS_GET,
    POSTS_NEW,
    POSTS_CLEAR,
    POSTS_NEXT,
    POSTS_PREVIOUS,
    POSTS_SET_SEARCH,
    POSTS_SET_NEXT,
    POSTS_SET_PREVIOUS,
    POSTS_FILTER,
    POSTS_DELETED,
    POSTS_ADD,
    USER_EDITED,
    POSTS_APPROVED,
    POSTS_UPDATE
} from '../actions/types';

const initialState = {
    list: [],
    backupList: [],
    new: null,
    pagination: 0,
    search: null,
    next: null,
    previous: null,
    lastEdited: Date.now(),
    lastEditedID: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case POSTS_UPDATE:
            state.list.forEach((post, index) => {
                if (post._id === action.payload._id) {
                    state.list[index] = action.payload;
                }
            });
            return {
                ...state,
                list: state.list,
                lastEdited: Date.now(),
                lastEditedID: action.payload._id
            };
        case POSTS_APPROVED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload) {
                    state.list[index].approved = true;
                }
            });
            return {
                ...state,
                list: state.list
            };
        case POSTS_DELETED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload) {
                    state.list.splice(index, 1);
                }
            });
            return {
                ...state,
                list: state.list
            };
        case POSTS_ADD:
            state.list.push(action.payload);
            return {
                ...state,
                list: state.list
            };
        case POSTS_SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                next: null,
                previous: null,
                pagination: 1
            };
        case POSTS_SET_NEXT:
            return {
                ...state,
                next: action.payload,
                previous: null
            };
        case POSTS_SET_PREVIOUS:
            return {
                ...state,
                previous: action.payload,
                next: null
            };
        case POSTS_FILTER:
            return {
                ...state,
                list: action.payload,
                pagination: 1,
                new: null
            };
        case POSTS_GET:
            let backupList = [...action.payload];
            backupList.pop();
            return {
                ...state,
                list: action.payload,
                backupList,
                pagination: 1
            };
        case POSTS_NEXT:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination + 1,
                new: null
            };
        case POSTS_PREVIOUS:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination - 1,
                new: null
            };
        case POSTS_NEW:
            if (state.list.length > 9)
                state.list.pop();
            return {
                ...state,
                list: [
                    action.payload,
                    ...state.list
                ],
                new: action.payload._id,
                search: null,
                next: null,
                pagination: 1,
                previous: null,
                lastEdited: Date.now(),
                lastEditedID: action.payload._id
            };
        case USER_EDITED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload._id) {
                    state.list[index] = action.payload;
                }
            });
            return {
                ...state,
                list: state.list,
                new: action.payload._id,
                lastEdited: Date.now()
            };
        case POSTS_CLEAR:
            return {
                ...state,
                new: null
            };
        default:
            return state;
    }
}
