import {
    COMMENTS_ADD,
    COMMENTS_APPROVED,
    COMMENTS_DELETED,
    COMMENTS_GET, COMMENTS_REPLIED,
    POSTS_ADD,
    POSTS_APPROVED,
    POSTS_DELETED
} from '../actions/types';

const initialState = {
    list: [],
    backupList: [],
    new: null,
    pagination: 0,
    search: null,
    next: null,
    previous: null,
    lastEdited: Date.now(),
    answered: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case COMMENTS_REPLIED:
            state.list.forEach((comment, index) => {
                if (comment._id === action.payload._id) {
                    state.list[index] = action.payload;
                }
            });
            let random;
            do {
                random = Math.random();
            } while (random === state.answered);
            return {
                ...state,
                list: state.list,
                answered: random
            };
        case COMMENTS_APPROVED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload) {
                    state.list[index].approved = true;
                }
            });
            return {
                ...state,
                list: state.list
            };
        case COMMENTS_GET:
            let backupList = [...action.payload];
            backupList.pop();
            return {
                ...state,
                list: action.payload,
                backupList,
                pagination: 1
            };
        case COMMENTS_DELETED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload) {
                    state.list.splice(index, 1);
                }
            });
            return {
                ...state,
                list: state.list
            };
        case COMMENTS_ADD:
            return {
                ...state,
                list: [action.payload, ...state.list]
            };
        default:
            return state;
    }
}
