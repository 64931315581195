import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_USER } from './types';

import getUsers from "./users/get";
import { getFiles } from "./filesActions";

// Register User
export const registerUser = (userData) => dispatch => {
    axios
        .post('/api/users/register', userData)
        .then(res => {
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
            dispatch(getUsers());
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data.errors
        }));
};

// Edit User
export const editUser = userData => dispatch => {
    const { username, firstName, lastName, password, password2, role, _id } = userData;
    console.warn({ username, firstName, lastName, password, password2, role, _id })
    axios
        .put(`/api/users/${_id}`, { username, firstName, lastName, password, password2, role })
        .then(() => dispatch(getUsers()));
};


// Delete User
export const deleteUser = email => dispatch => {
    axios
        .post('/api/users/delete', { email })
        .then(res => dispatch(getUsers()));
};

// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
};

// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
    // remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will also set isAuthenticated to false
    dispatch(setCurrentUser({}));
};
