import {
    USERS_GET,
    USERS_REGISTERED,
    USERS_CLEAR,
    USERS_NEXT,
    USERS_PREVIOUS,
    USERS_SET_SEARCH,
    USERS_SET_NEXT,
    USERS_SET_PREVIOUS,
    USERS_FILTER,
    USERS_DELETED,
    USERS_ADD,
    USER_EDITED
} from '../actions/types';

const initialState = {
    list: [],
    backupList: [],
    new: null,
    pagination: 0,
    search: null,
    next: null,
    previous: null,
    lastEdited: Date.now()
};

export default (state = initialState, action) => {
    switch(action.type) {
        case USERS_DELETED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload) {
                    state.list.splice(index, 1);
                }
            });
            return {
                ...state,
                list: state.list
            };
        case USERS_ADD:
            state.list.push(action.payload);
            return {
                ...state,
                list: state.list
            };
        case USERS_SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                next: null,
                previous: null,
                pagination: 1
            };
        case USERS_SET_NEXT:
            return {
                ...state,
                next: action.payload,
                previous: null
            };
        case USERS_SET_PREVIOUS:
            return {
                ...state,
                previous: action.payload,
                next: null
            };
        case USERS_FILTER:
            return {
                ...state,
                list: action.payload,
                pagination: 1,
                new: null
            };
        case USERS_GET:
            let backupList = [...action.payload];
            backupList.pop();
            return {
                ...state,
                list: action.payload,
                backupList,
                pagination: 1
            };
        case USERS_NEXT:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination + 1,
                new: null
            };
        case USERS_PREVIOUS:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination - 1,
                new: null
            };
        case USERS_REGISTERED:
            if (state.list.length > 9)
                state.list.pop();
            return {
                ...state,
                list: [
                    action.payload,
                    ...state.list
                ],
                new: action.payload._id,
                search: null,
                next: null,
                pagination: 1,
                previous: null,
                lastEdited: Date.now()
            };
        case USER_EDITED:
            state.list.forEach((user, index) => {
                if (user._id === action.payload._id) {
                    state.list[index] = action.payload;
                }
            });
            return {
                ...state,
                list: state.list,
                new: action.payload._id,
                lastEdited: Date.now()
            };
        case USERS_CLEAR:
            return {
                ...state,
                new: null
            };
        default:
            return state;
    }
}
