// Get all users
import axios from "axios";
import {USERS_GET} from "../types";

const get = params => dispatch => {
  !params && (params = {});
  params = {limit: 10, sort: {dateRegistered: -1}, ...params};
  axios
    .post('/users/get', params)
    .then(res => dispatch({
      type: USERS_GET,
      payload: res.data
    }))
    .catch(err => console.log(err));
};

export default get;
