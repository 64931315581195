import {
    SURVEY_GET,
    SURVEY_CLEAR,
    SURVEY_NEXT,
    SURVEY_PREVIOUS,
    SURVEY_SET_SEARCH,
    SURVEY_SET_NEXT,
    SURVEY_SET_PREVIOUS,
    SURVEY_FILTER,
    SURVEY_DELETED,
    SURVEY_ADD,
    SURVEY_EDITED,
    SURVEY_REGISTERED
} from '../actions/types';

const initialState = {
    list: [],
    backupList: [],
    new: null,
    pagination: 0,
    search: null,
    next: null,
    previous: null,
    lastEdited: Date.now()
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SURVEY_DELETED:
            state.list.forEach((survey, index) => {
                if (survey._id === action.payload) {
                    state.list.splice(index, 1);
                }
            });
            return {
                ...state,
                list: state.list
            };
        case SURVEY_ADD:
            state.list.push(action.payload);
            return {
                ...state,
                list: state.list
            };
        case SURVEY_SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                next: null,
                previous: null,
                pagination: 1
            };
        case SURVEY_SET_NEXT:
            return {
                ...state,
                next: action.payload,
                previous: null
            };
        case SURVEY_SET_PREVIOUS:
            return {
                ...state,
                previous: action.payload,
                next: null
            };
        case SURVEY_FILTER:
            return {
                ...state,
                list: action.payload,
                pagination: 1,
                new: null
            };
        case SURVEY_GET:
            let backupList = [...action.payload];
            backupList.pop();
            return {
                ...state,
                list: action.payload,
                backupList,
                pagination: 1
            };
        case SURVEY_NEXT:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination + 1,
                new: null
            };
        case SURVEY_PREVIOUS:
            return {
                ...state,
                list: action.payload,
                pagination: state.pagination - 1,
                new: null
            };
        case SURVEY_REGISTERED:
            if (state.list.length > 9)
                state.list.pop();
            return {
                ...state,
                list: [
                    action.payload,
                    ...state.list
                ],
                new: action.payload._id,
                search: null,
                next: null,
                pagination: 1,
                previous: null,
                lastEdited: Date.now()
            };
        case SURVEY_EDITED:
            state.list.forEach((survey, index) => {
                if(action.payload.status==='ACTIVE'){
                    if(state.list[index].status==='ACTIVE') state.list[index].status = 'COMPLETED';
                }
                if (survey._id === action.payload._id) {
                    state.list[index] = action.payload;
                }
            });
            return {
                ...state,
                list: state.list,
                new: action.payload._id,
                lastEdited: Date.now()
            };
        case SURVEY_CLEAR:
            return {
                ...state,
                new: null
            };
        default:
            return state;
    }
}
