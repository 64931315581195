import {
  GET_FILES,
  UPLOAD_GALLERY,
  UPLOAD_MEDIA,
  UPLOAD_PROGRESS,
  FILES_GALLERY_REMOVE,
  FILES_MEDIA_REMOVE,
  POSTS_NEW,
  POSTS_UPDATE,
  FILES_APPROVED,
  FILES_REMOVED,
  SET_FILES,
} from '../actions/types';

const initialState = {
  all: [],
  progress: -1,
  error: false,
  gallery: [],
  media: [],
  approved: null,
  removed: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILES:
      return {
        ...state,
        media: action.media,
        gallery: action.gallery
      };
    case FILES_REMOVED:
      return {
        ...state,
        removed: action.payload
      };
    case FILES_APPROVED:
      return {
        ...state,
        approved: action.payload
      };
    case POSTS_NEW:
      return {
        ...state,
        gallery: [],
        media: []
      };
    case POSTS_UPDATE:
      return {
        ...state,
        gallery: [],
        media: []
      };
    case GET_FILES:
      return {
        ...state,
        all: action.payload.reverse()
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.progress,
        error: action.error
      };
    case UPLOAD_GALLERY:
      return {
        ...state,
        gallery: [...state.gallery, ...action.payload]
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        media: [...state.media, ...action.payload]
      };
    case FILES_GALLERY_REMOVE:
      let galleryIndex = 0;
      state.gallery.forEach((item, index) => {
        if (item._id === action.payload) galleryIndex = index;
      });
      state.gallery.splice(galleryIndex, 1);
      return {
        ...state,
        gallery: [...state.gallery]
      };
    case FILES_MEDIA_REMOVE:
      let mediaIndex = 0;
      state.media.forEach((item, index) => {
        if (item._id === action.payload) mediaIndex = index;
      });
      state.media.splice(mediaIndex, 1);
      return {
        ...state,
        media: [...state.media]
      };
    default:
      return state;
  }
}
