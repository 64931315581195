import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import userReducer from "./userReducer";
import filesReducer from "./filesReducer";
import postReducer from "./postReducer";
import commentsReducer from "./commentsReducer";
import chatReducer from './chatReducer';
import surveyReducer from './surveyReducer';

export default combineReducers({
    auth: authReducer,
    comments: commentsReducer,
    errors: errorReducer,
    files: filesReducer,
    post: postReducer,
    user: userReducer,
    chats: chatReducer,
    survey: surveyReducer
});
